import React, { Suspense, Component } from 'react';
import config from "./../config/config.json";
import Metas from "./Metas";
import { Stepper, Step, StepLabel, Button, Grid, Container, Icon, Hidden } from '@material-ui/core';
import ContainerFixesTop from "./ContainerFixesTopV2";
import SecureSite from "./layout/SecureSite";
import ContainerSticky from 'react-sticky-el';
import ContainerFixedMobile from "react-headroom"
import { connect } from "react-redux";
import { getInfoStepAction, updatePreloadAction } from "../actions/StepActionV2";
import { GetMessagesTopSidebar } from './CardDetailMessages'
import PreloadStep from "./layout/preloads/PreloadStep";
import PreloadStepFull from "./layout/preloads/PreloadStepFull";

import { animateScroll as scroll } from 'react-scroll'
import TimerV2 from './TimerV2';
import Maintitle from "./Maintitle";
//import DetailDialog from "./layout/purchase/DetailDialog";
import Copyright from './layout/Copyright';
//import Quotes from '../components/common/Quotes';
import TotalApagar from './TotalApagar';
import { DlCheckoutSteps, DlExtrasAsistencia } from '../services/DatalayerGeneral'


class StepsV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setActiveStep: (this.props.activeStep) ? this.props.activeStep : 0,
            validate: false,
            addToCart: {},
            purchaseDetail: {},
            data: {},
            selectedData: {},
            bank: {},
            creditCard: {},
            paymentFee: {},
            dataStep: [],

            triggerDlExtrasAsistencia: false,
            triggerDlCheckoutSteps: false
        }
        this.handleNext = this.handleNext.bind(this)
        this.component = null;
    }

    componentDidMount() {
        // console.log("StepV2 renderizado")
        // console.log("this.props.purchaseDetail",  this.props.purchaseDetail)
        let newAddToCart = {}
        newAddToCart["priceDetailProduct"] = this.props.purchaseDetail.priceDetail.filter(
            (element) => element.addToCart === true
        );
        newAddToCart["priceDetailCards"] = []

        this.setState({ data: this.props.data, purchaseDetail: this.props.purchaseDetail, addToCart: newAddToCart, triggerDlExtrasAsistencia: true })
    }

    shouldComponentUpdate(nextProps, prevState) {
        if (this.state.setActiveStep !== prevState.setActiveStep) {
            this.setState({ triggerDlCheckoutSteps: true });
        }

        if (!nextProps.renderPayments) {
            // console.log("No renderiza StepsV2")
            return false
        } else {
            // console.log("renderiza StepsV2")
            return true
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // cuando hay un campio en las propiedades se ejecuta esta funcion
        this.setState({ bank: nextProps.bank, creditCard: nextProps.creditCard, paymentFee: nextProps.paymentFee, selectedData: nextProps.selectedData, purchaseDetail: nextProps.purchase, addToCart: nextProps.addToCart, data: nextProps.dataStep });
    }

    handleNext(skipStep = false) {
        let prevStep = this.state.setActiveStep;
        // console.log("this.props.steps[prevStep]", this.props.steps[prevStep])
        // Arma finishedStep del paso
        let responseChildComponent = skipStep
            ? { "data": [], "continue": true }
            : this.component.handleSubmit();
        //const responseChildComponent = {'continue': true,'data': {"datos":"datos de prueba"}};   // PARA TESTING
        // console.log("responseChildComponent", responseChildComponent)

        let datalayerActualStep = this.props.steps.filter(persona => persona.active == "true");

        // console.log('%c ---- handleNext / responseChildComponent.continue: ', 'color: #E65412; background: #fdeee7', responseChildComponent.continue);
        // console.log('%c ---- handleNext / this.state.setActiveStep: ', 'color: #E65412; background: #fdeee7', this.state.setActiveStep);


        if (responseChildComponent.continue) {

            // Si es el ultimo paso
            if (this.state.setActiveStep === this.props.steps.length - 1) {
                return this.props.getFinalStep(responseChildComponent.data, this.props.steps[prevStep]);
            } else {
                // Pasa al paso sigiente.
                let newSetActiveStep = this.state.setActiveStep + 1
                this.setState({ setActiveStep: newSetActiveStep });
                // console.log("Seteando activeStep", newSetActiveStep);

                this.nextStepAction = this.props.steps[newSetActiveStep].action;
                // console.log("Seteando nextStepAction", this.nextStepAction);
            }

            // Ejecuta la accion que llamaŕa al endpoint correspondiente a ese paso.
            //console.log("Consultando GetInfoStepAction")
            // console.log("STEPSV2 this.state.addToCart", this.state.addToCart)
            this.props.dispatchGetInfoStepAction(
                this.props.ip,
                this.props.hash,
                this.nextStepAction,
                this.state.addToCart,
                responseChildComponent.data,
                this.props.steps[prevStep]
            );
            this.props.updatePreloadAction(false);

            scroll.scrollToTop();
        }
    }


    render() {
        const activeStep = this.state.setActiveStep;
        const module = this.props.module.toLowerCase();
        const stepNumber = (this.state.setActiveStep === 0) ? this.state.setActiveStep + 1 : this.state.setActiveStep;
        console.log("this.props.preload: ", this.props.preload)
        if (this.state.triggerDlCheckoutSteps == true && this.props.preload == true) {
            console.log('%c this.state.triggerDlCheckoutSteps: ', 'color: #E65412; background: #fdeee7', this.state.triggerDlCheckoutSteps)
            DlCheckoutSteps(
                this.state.setActiveStep == 1 ? "addPaymentInfo" : this.state.setActiveStep == 2 ? "addPassengerInfo" : this.state.setActiveStep == 3 ? "confirmationPage" : "",
                this.props.module,
                null,
                null);
            this.setState({ triggerDlCheckoutSteps: false });
        }

        this.props.steps.forEach(function (step) {
            if (step.stepper !== undefined) {
                if (step.id === step.stepper.step) {

                }
            }
        })

        const StepperCustom = (props) => {
            let showStepper = true;
            // Validacion para mostrar o no el componente stepper
            if (this.props.steps[activeStep].stepper !== undefined) {
                //console.log("Tiene stepper");
                if (!this.props.steps[activeStep].stepper.show) {
                    //console.log("Tiene show en false");
                    showStepper = false;
                }
            }

            // Validacion para mostrar o no el paso en el stepper.
            // Y para definir que paso mostrar como activo en cada step.

            let allowedSteps = []; // array que dice si cada paso se muestra o no 
            let activeIndexSteps = {};  // array que dice que numero de paso mostrar como activo en cada step. 
            this.props.steps.forEach(function (step) {
                //console.log("----------------------------")
                activeIndexSteps[step.name] = step.id; // por defecto se muestra como activo el mismo paso
                if (step.stepper !== undefined) {
                    //console.log("Tiene stepper el paso ", step.name)
                    if (step.stepper.show) {
                        //console.log("Show en true ")
                        // stepper.step indica el step en la vista bajo el cual se mostrará este paso
                        if (step.id === step.stepper.step) {
                            //console.log("Step.id = stepper.step");
                            // Si coinciden, cuenta como un paso.
                            allowedSteps.push(step)
                        } else {
                            //console.log("Step.id <> stepper.step ",  step.stepper.step);
                            activeIndexSteps[step.name] = step.stepper.step
                        }
                    } else {// si show=false, el stepper nisiquiera se mostrará.
                        //console.log("Step.id <> stepper.step ",  step.stepper.step);
                        activeIndexSteps[step.name] = step.stepper.step
                    }
                } else {
                    allowedSteps.push(step);
                }
            })

            //console.log("---activeIndexSteps", activeIndexSteps);

            // Busca el id de step a mostrar para el paso activo
            let activeStepId = activeIndexSteps[this.props.steps[activeStep].name]
            //console.log("---activeStepId", activeStepId);
            // Obtiene el objeto de ese id de step
            let activeStepObj = this.props.steps.filter(function (step) {
                return step.id == activeStepId;
            })[0]
            //console.log("---activeStepObj", activeStepObj);
            // Se fija en que posición de la lista del stepper se encuentra.
            let activeStepNumber = allowedSteps.indexOf(activeStepObj);
            //console.log("---activeStepNumber", activeStepNumber)
            // Pasará esa posición al parametro activeStep del componente Stepper.

            return (<>
                {showStepper &&
                    <section className="stepper_container">
                        <Stepper activeStep={activeStepNumber} className="stepper_component">
                            {allowedSteps.map((step, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                // Validacion para mostrar o no el paso en el stepper.
                                return (
                                    <Step key={index} {...stepProps}>
                                        <StepLabel {...labelProps}>{step.name}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </section>
                }
            </>)
        }

        const StepContent = (props) => {
            const { stepActive, stepActiveData, purchaseDetail, products } = props
            const data = this.state.data;

            let Component = React.lazy(() => import(`./scenesV2/${stepActive.nameContainer}`));

            if (stepActive.nameContainer == "Crossell") {
                if (stepActive.data.items !== undefined && stepActive.data.items.length > 0) {
                    // console.log("entro a if crossell");
                    {
                        !this.state.triggerDlExtrasAsistencia &&
                            DlExtrasAsistencia(
                                "extrasAsistencia",
                                this.props.module,
                                this.props.products.pivot[0].data,
                                this.props.steps[activeStep].data.items[0].cardProducts);
                    }
                    return (<> <Suspense fallback={<PreloadStep />}>
                        <Component
                            ref={element => { this.component = element }}
                            selectedData={this.props.selectedData}
                            selected={this.props.selected}
                            purchaseDetail={this.props.purchaseDetail}
                            data={this.props.steps[activeStep].data}
                            stepActive={stepActive}
                            conditionsUrl={config.client.conditionsUrl}
                            module={this.props.module}
                            searchData={this.props.products.pivot[0]}
                        //callbackHandleNext = {this.handleNext}
                        />

                    </Suspense>  </>)
                } else { // Si no se ofrecen opciones de crossell.
                    // console.log("salteando step...");
                    this.handleNext(true);
                    return <div> Cargando siguiente paso...</div>
                }
            } else {
                return (
                    <>{/* activeStep: {activeStep} | nameContainer: {stepActive.nameContainer} */}
                        <Suspense fallback={<PreloadStep />}>

                            <Component
                                ref={element => { this.component = element }}
                                module={this.props.module}
                                hash={this.props.hash}
                                preload={this.props.preload}
                                allowFormMethodPayment={this.props.allowFormMethodPayment}
                                allowForm={this.props.allowForm}
                                data={data}
                                creditCard={this.state.creditCard}
                                bank={this.state.bank}
                                paymentFee={this.state.paymentFee}
                                purchaseDetail={purchaseDetail}
                                products={products}
                                conditionsUrl={config.client.conditionsUrl}
                                paymentSelected={this.props.paymentSelected}
                                stepActiveData={stepActiveData}
                                stepActive={stepActive}
                                addToCart={this.props.addToCart}
                                selectedData={this.props.selectedData}
                                searchData={this.props.products.pivot[0]}
                                dlTrigguerCheckoutBegin={true}
                            />
                        </Suspense>

                    </>
                )
            }
        }

        // Junta todos los mensajes que lleguen en la clave response>step>data>messages.
        let keysData = Object.keys(this.state.data);
        let arrayMessages = [];
        keysData.forEach(key => {
            let keyObject = this.state.data[key];
            //console.log("keyObject",keyObject);
            if (keyObject != undefined && keyObject != null) {
                if (keyObject.response != undefined && keyObject.response != null) {
                    if (keyObject.response.step != undefined && keyObject.response.step != null) {
                        if (keyObject.response.step.data != undefined && keyObject.response.step.data != null) {
                            if (keyObject.response.step.data.messages != undefined) {
                                arrayMessages = arrayMessages.concat(keyObject.response.step.data.messages);
                            }
                        }
                    }
                }
            }
        });

        return (<>
            <Metas client={config.client} module={module} />
            <div className={`steps ${(!this.props.preload) ? 'preload-open' : ''}`}>
                {(!this.props.preload) && <PreloadStepFull preload={this.props.preloadCurrent[`step${stepNumber}`]} />}

                {/* STEPS ICONS */}
                <StepperCustom />
                {/* ENDS STEPS ICONS */}

                {/* STEPS CONTENT */}

                {/* PURCHASE DETAIL MOBILE */}
                <Hidden mdUp>
                    <ContainerFixedMobile>
                        <div className="sticky-inner">
                            <ContainerFixesTop
                                addToCartPurchase={this.state.addToCart}
                                purchaseDetail={this.props.purchaseDetail}
                                timer={this.props.timer}
                                expired={this.props.expired}
                                products={this.props.products}
                                selectedData={this.state.selectedData}
                                installment={this.state.paymentFee}
                                layout={this.props.layout}
                                module={module}
                            />
                        </div>
                    </ContainerFixedMobile>
                </Hidden>
                {/* END PURCHASE DETAIL MOBILE */}

                <Container>
                    <Grid container spacing={2}>
                        <Grid item md={8} className="col_content">
                            <div className="steps_container">
                                {this.props.layout.timer.template === 'timerTopContent' && (
                                    <Hidden smDown>
                                        <TimerV2
                                            layoutTimer={this.props.layout.timer}
                                            timer={this.props.timer}
                                            expired={this.props.expired}
                                            module={module}
                                        />
                                    </Hidden>
                                )}

                                {activeStep === 0 &&
                                    <Maintitle client={config.client} module={module} />
                                }

                                <StepContent
                                    stepActive={this.props.steps[activeStep]}
                                    stepActiveData={this.props.steps[activeStep].data}
                                    purchaseDetail={this.props.purchaseDetail}
                                    products={this.props.products}
                                />

                                <Hidden smDown>
                                    <div className="col_content_copyright">
                                        <Copyright copyright={config.client.copyright} footerLinks={config.client.footerLinks} />
                                    </div>
                                </Hidden>
                            </div>


                        </Grid>
                        <Hidden smDown>
                            {/* LATERAL DESKTOP */}
                            <Grid item md={4} className="col_sidebar">
                                <ContainerSticky>
                                    <ContainerFixesTop
                                        addToCartPurchase={this.state.addToCart}
                                        purchaseDetail={this.props.purchaseDetail}
                                        timer={this.props.timer}
                                        expired={this.props.expired}
                                        products={this.props.products}
                                        selectedData={this.state.selectedData}
                                        installment={this.state.paymentFee}
                                        layout={this.props.layout}
                                        module={module}
                                    />
                                </ContainerSticky>
                                <GetMessagesTopSidebar messages={arrayMessages} />
                                <SecureSite client={config.client} />
                            </Grid>
                            {/* END LATERAL DESKTOP */}
                        </Hidden>
                    </Grid>
                </Container>

                {/* ENDS STEPS CONTENT */}

                <div className="steps_buttons">
                    <Container>
                        {activeStep === this.props.steps.length ? (
                            <Button onClick={() => this.handleReset()}>Inicio</Button>
                        ) : (
                            <div className="steps_buttons_inner">
                                <span className="text_total_sumary">

                                    <TotalApagar
                                        addToCartPurchase={this.state.addToCart}
                                        purchaseDetail={this.props.purchaseDetail}
                                        timer={this.props.timer}
                                        expired={this.props.expired}
                                        products={this.props.products}
                                        selectedData={this.state.selectedData}
                                        installment={this.state.paymentFee}
                                        layout={this.props.layout}
                                        module={module}
                                    ></TotalApagar>
                                    {/*  {this.props.purchase!==null && this.props.purchase.totalAmount !== undefined ? (
                                        <>
                                            <span className="text">Total a pagar</span>
                                            <span className="price">{this.props.purchase.currency}<span className="amount">{this.props.purchase.totalAmount}</span></span>
                                        </>
                                    ) : (
                                            <>
                                                <span className="text">Total a pagar</span>
                                                <span className="price">{this.props.purchaseDetail.currency}<span className="amount">{this.props.purchaseDetail.totalAmount}</span></span>
                                            </>
                                        )} */}
                                    {/* <DetailDialog icon="info_outlined" installment={this.state.paymentFee} selectedData={this.state.selectedData} purchaseDetail={this.props.purchaseDetail} products={this.props.products} /> */}
                                </span>

                                <Button
                                    className="btn_next_step" id="continue" color="primary" variant="contained" onClick={() => this.handleNext()} endIcon={<Icon>arrow_forward</Icon>}>
                                    {activeStep === this.props.steps.length - 1 ? 'Pagar' : 'Continuar'}
                                </Button>
                            </div>
                        )}
                    </Container>
                </div>
            </div>
        </>)
    }
}

const mapStateToProps = reducers => {
    return reducers.stepReducerV2;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetInfoStepAction: (ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep, prevStep) =>
            dispatch(
                getInfoStepAction(
                    ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep, prevStep
                ),
            ),
        updatePreloadAction: (boolean) => {
            dispatch(updatePreloadAction(boolean))
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StepsV2);
